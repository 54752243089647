<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ClassroomReservationViewPopup :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowSchedule" @close="pop.hideSchedule"></ClassroomReservationViewPopup>
        <ClassroomSitePopup v-if="pop.isShowClassroomSite" @close="pop.isShowClassroomSite=false"></ClassroomSitePopup>
        <ClassroomRentalcostPopup v-if="pop.isShowClassroomCost" @close="pop.isShowClassroomCost=false"></ClassroomRentalcostPopup>

        <CarrotTitle title="강의장 예약하기">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="pop.isShowClassroomSite=true" class="btn-default">강의실 지도</button>
                <button @click="pop.isShowClassroomCost=true" class="btn-default ml-20">강의실 대여요금</button>
                <button @click="bbs.showAnalysis" class="btn-default ml-20">통계</button>
            </div>
            - 예약현황을 클릭하시면 상세 내용을 보실 수 있습니다.<br>
            - 본인이 예약한 현황은 하늘색으로 표기되며, 이미 예약된 현황은 내부는 연녹색, 고객용은 주황색으로 표기됩니다.
            <div class="room-info">
              <div class="clear room-color mr-10">
                <div style="background-color:rgba(255,118,0,0.6);"></div> <p>&nbsp;- 고객용</p>
              </div>
              <div class="clear room-color mr-10">
                <div style="background-color:rgba(5,171,25,0.6);"></div> <p>&nbsp;- 내부용</p>
              </div>
              <div class="clear room-color">
                <div style="background-color:rgba(0,114,255,0.6);"></div> <p>&nbsp;- 본인</p>
              </div>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select mt-40">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <carrot-date-picker @change="bbs.doSearch" v-model="bbs.sdate" class="inline-block w-160px"></carrot-date-picker>
                        <carrot-date-picker @change="bbs.doSearch" v-model="bbs.edate" class="inline-block w-160px"></carrot-date-picker>
                        <!--<carrot-date-picker @change="bbs.doSearch" v-model="bbs.today" class="inline-block w-160px"></carrot-date-picker>-->
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>

                    <div class="dp-table w-100per mt-40 mb-20">
                        <button @click="bbs.showDays" class="btn-default float-left w-70px">일별</button>
                        <button @click="bbs.showWeeks" class="btn-default float-left w-70px">주별</button>
                        <button @click="bbs.showMonths" class="btn-default float-left w-70px">월별</button>
                        <button @click="bbs.addSchedule('0', '')" class="btn-default float-right">강의실 예약하기</button>
                    </div>

                    
                    <div class="dayTable-box">
                        <div class="div-dayTable group-label">
                            <div class="colgroup">
                                <div v-for="(irow, i) in bbs.groupList" :key="i" :style="{ 'width':(bbs.colSize*irow.cnt_classroom)+'px'}" class="col"></div>
                            </div>
                            <div class="thead">
                                <div class="tr">
                                    <div v-for="(irow, i) in bbs.groupList" :key="i" class="cell th">
                                        <p class="tit">{{ irow.cname }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <p class="dis-none"> {{total_cnt=0}} </p>
                        <div class="div-dayTable middle-label"  v-for="(week, i) in bbs.week" :key="i">
                            <div class="week-txt" :class="{'first-margin':week.name=='월'}">
                              {{week.name}}
                            </div>
                            <div class="colgroup">
                                <div v-for="i in bbs.list" :key="i" :style="{ 'width':bbs.colSize+'px'}" class="col"></div>
                            </div>

                            <div class="reserve-body" >
                              <p class="dis-none"> {{max_cnt=2}} </p>
                              <div v-for="(irow, i) in bbs.list" :key="i" class="reserve-cell" :style="{ 'width':1154/bbs.list.length+'px'}"  >
                                <p class="dis-none"> {{cnt=0}} </p>
                                <div v-for="(jrow, j) in irow.list" :key="j" :style="{ 'top':21*cnt+'px', 'height':'20px'}"
                                      :class="{'reserve-bg-out':jrow.is_owner=='N' && jrow.in_reserved=='N', 'reserve-bg-in':jrow.is_owner=='N'&& jrow.in_reserved=='Y', 'reserve-bg-owner' : jrow.is_owner=='Y', 'none' :jrow.sday_txt.indexOf(week.name)<0}" >
                                  <p v-if="jrow.sday_txt.indexOf(week.name)>=0" class="dis-none">{{cnt++}} </p>
                                  <p v-if="max_cnt<cnt" class="dis-none">{{max_cnt=cnt}} </p>
                                  <button v-if="jrow.sday_txt.indexOf(week.name)>=0" @click="pop.showSchedule(jrow.idx)" :class="{ 'owner':jrow.is_owner=='Y' }" class="btn-reserve" :title="'['+jrow.company_name+'] '+jrow.title+' ('+jrow.stime+'~'+jrow.etime+')'"></button>
                                </div>
                              </div>
                              <p class="dis-none"> {{total_cnt=total_cnt+max_cnt}} </p>
                            </div>

                          <div class="tbody" :style="{ 'height':21*max_cnt+'px'}" >
                            <template >
                              <div class="tr" >
                                <div class="cell td" >
                                </div>
                              </div>
                            </template>
                          </div>

                          <!-- thead-->
                          <div class="thead" v-if="week.name=='월'"  >
                            <div class="tr" >
                              <div v-for="(irow, i) in bbs.list" :key="i" class="cell th " :class="{'active':irow.preview_text}">
                                <p class="txt"   @mouseover="bbs.isShow=irow.idx" @mouseleave="bbs.isShow=0">{{ irow.cnum }}</p>
                                <div v-if="bbs.isShow==irow.idx && irow.preview_text " class="over-text" >
                                  <p class="txt"><span v-html="irow.preview_text.replace('\n', '<br>')"></span></p>
                                </div>

                              </div>
                            </div>
                          </div>
                          <!-- 구분선 -->
                          <div class="thead" v-if="week.name=='일'"  >
                            <div class="tr"  style="background-color: transparent">
                              <div v-for="(irow, i) in bbs.list" :key="i" class="cell none " >
                                <p :style="{ 'height':20*(total_cnt+max_cnt)+'px', 'bottom':-20*max_cnt+'px','border-left':'1px solid #ddd','position':'absolute','z-index':'-1'}">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import ClassroomReservationViewPopup from '@/components/popup/ResourceManagement/ClassroomReservationViewPopup.vue'
import ClassroomSitePopup from '@/components/popup/ResourceManagement/ClassroomSitePopup.vue'
import ClassroomRentalcostPopup from '@/components/popup/ResourceManagement/ClassroomRentalcostPopup.vue'

export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker,
        ClassroomReservationViewPopup,
        ClassroomSitePopup,
        ClassroomRentalcostPopup
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            idx   : 0,
            tdate : "",
            // Popup 노출 여부
            isShowClassroomSite : false,
            isShowClassroomCost : false,
            isShowSchedule      : false,

            showSchedule : (idx) => {
                sessionStorage.setItem('ClassroomReservationWeek', JSON.stringify({
                    today : bbs.today
                }));
                pop.idx   = idx;
                pop.tdate = bbs.today;
                pop.isShowSchedule = true;
                console.log(pop);
            },
            hideSchedule : () => {
                pop.isShowSchedule = false;
                pop.idx = 0;
                bbs.doSearch();
            },
        });


        const bbs = reactive({
            colSize     : 0,
            date_labels : [],
            time_labels : [],
            time_count  : 0,

            today : "",
            sdate : "",
            edate : "",

            list : [], total : 0,

            doPrev : () => {
                let td = new Date(bbs.sdate);
                let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()-7);
                bbs.sdate = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                td = new Date(bbs.edate);
                nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()-7);
                bbs.edate = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();
            },

            doNext : () => {
                let td = new Date(bbs.sdate);
                let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()+7);
                bbs.sdate = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                td = new Date(bbs.edate);
                nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()+7);
                bbs.edate = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();

            },

            showAnalysis : () => {
                sessionStorage.setItem('ClassroomReservationWeek', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-ClassroomReservationStatistics'
                })
            },
            showDays : () => {
              sessionStorage.setItem('ClassroomReservationWeek', JSON.stringify({
                today : bbs.today
              }));
              router.push({
                name : 'ResourceManagement-ClassroomReservationDate'
              });
            },
            showMonths : () => {
              sessionStorage.setItem('ClassroomReservationWeek', JSON.stringify({
                today : bbs.today
              }));
              router.push({
                name : 'ResourceManagement-ClassroomReservationMonth'
              });
            },
            showWeeks : () => {
                sessionStorage.setItem('ClassroomReservationWeek', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-ClassroomReservationWeek'
                });
            },

            addSchedule : (idx_classroom, time) => {
                sessionStorage.setItem('ClassroomReservationAdd', JSON.stringify({
                    today : bbs.today
                }));
                if( idx_classroom > 0 ){
                    sessionStorage.setItem('ClassroomReservationAdd', JSON.stringify({
                        today : bbs.today,
                        time  : time,
                        idx_classroom : idx_classroom,
                    }));
                } else {
                    sessionStorage.removeItem('ClassroomReservationAdd');
                }
                router.push({
                    name : 'ResourceManagement-ClassroomReservationAdd'
                });
            },

            makeTimeSchedules : () => {
                let nd = new Date(bbs.today);
                let td = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate(), 7, 0, 0);

                for(let i=0; i<bbs.list.length; i++){
                    let irow = bbs.list[i];

                    for(let j=0; j<irow.list.length; j++){
                        let jrow = irow.list[j];

                        jrow.top    = 0;
                        jrow.height = 0;

                        let s = 0;
                        for(let k=td.getTime(), l=0; k<td.getTime()+(3600*1000*15); k+=1800*1000, l++){
                            let kd = new Date(k);

                            let ktime = (kd.getHours()<10?'0'+kd.getHours():kd.getHours())
                                      + ':' + (kd.getMinutes()<10?'0'+kd.getMinutes():kd.getMinutes());

                            if( jrow.stime == ktime ){
                                s = l;
                            }
                            if( jrow.stime <= ktime && ktime < jrow.etime ){
                                jrow.height +=3;
                            }

                        }

                        jrow.top = s*3;

                        irow.list[j] = jrow;
                    }
                }
            },

            doSearch : () => {
                let params = {
                    sdate : bbs.sdate,
                    edate : bbs.edate
                };
                axios.get('/rest/resourceManagement/getClassroomReservationWeek', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.groupList = res.data.group_list;
                        bbs.list  = res.data.list;
                        bbs.week  = res.data.week;
                        bbs.total = bbs.list.length;

                        bbs.colSize = 1154/bbs.total;

                        bbs.makeTimeSchedules();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            makeTimeLabels : () => {
                for(let i=7; i<23; i++){
                    if( i < 10 && i % 2==0){
                        bbs.time_labels.push( '0' + i + ':00');
                    } else if ( i == 22 ){
                        bbs.time_labels.push( i + ':00' );
                    } else {
                        if(i % 2==0)
                        bbs.time_labels.push( i + ':00');
                    }
                }

                bbs.time_count = bbs.time_labels.length - 1;
            },

        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let week = new Array('일','월','화','수','목','금','토');
            let day= week[nd.getDay()];

            let today = nd.getFullYear()
              + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
              + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());

            if(day=='월')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate());
            if(day=='화')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-1);
            if(day=='수')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-2);
            if(day=='목')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-3);
            if(day=='금')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-4);
            if(day=='토')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-5);
            if(day=='일')
              nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-6);

            let sdate = nd.getFullYear()
                + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());

            nd = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()+6);
            let edate = nd.getFullYear()
                + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());

            bbs.today = today;
            bbs.sdate=sdate;
            bbs.edate=edate;


            bbs.makeTimeLabels();

            bbs.doSearch();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
.room-info{
  .room-color{
    display: inline-block;
    p{
      float: left;
    }
    div{
      width: 18px;
      height: 18px;
      float: left;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
.dayTable-box { 
    width:1200px;
    margin-left:40px;
    .day-time {
        position:relative;
        p {
          position:relative; margin-top:8px; margin-right:5px; height:4px;z-index:2; text-align:right;
          font-size:8px;
        }
        .mon {
          &:first-child {
            margin-top:62px;
          }
        }
        .group-title {
            position:absolute;top:0;left:0;margin:0; width:100%; height:70px;font-size:14; font-weight:bold;color:#555;border-right:solid 1px #ddd;line-height:70px;text-align:center;background-color:#f9f9f9; z-index:0;
        }
        .txt{
          position:absolute;  font-size:16px;right:15px;font-weight: bold;
        }
    }
    .dis-none{
      display:none;
    }

    .div-dayTable{
        position:relative;
        border-left: 1px solid #616161;
        border-right: 1px solid #616161;
        &.group-label {
            .tr .cell {
                height:40px;

            }
        }
        .week-txt{
          position:absolute;
          top:0px;
          left:-30px;
          margin:0;
          font-size:14px;
          font-weight:bold;
          float:left;
        }
        .first-margin{
          top:25px;
        }
        &.middle-label {
            border-top: solid 1px #ddd; margin-top:-1px;
            .thead {
                .tr {
                    .th {
                      border-bottom: solid 2px #616161;
                      &:nth-child(14) {
                        border-left: 2px solid #616161;
                      }
                      &:nth-child(17) {
                        border-left: 2px solid #616161;
                      }
                      &:nth-child(19) {
                        border-left: 2px solid #616161;
                      }
                      &:nth-child(25) {
                        border-left: 2px solid #616161;
                      }
                    }
                }
            }
        }

        .tr {
          .active{
            background-color: #D8D8D8
          }
            .none{
              background-color: transparent;
              height:0px;
              border:0px;
            }
            .cell {
                height:12px;
                &.td{
                  &:nth-child(14) {
                    border-left: 2px solid #616161;
                  }
                  &:nth-child(17) {
                    border-left: 2px solid #616161;
                  }
                  &:nth-child(19) {
                    border-left: 2px solid #616161;
                  }
                  &:nth-child(25) {
                    border-left: 2px solid #616161;
                  }
                }

                &.th{
                    // &:nth-child(2) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(3) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(4) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(5) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(5) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(6) {
                    //     border-left: 2px solid #616161;
                    // }
                }
            }
        }

        .reserve-body {
            position:absolute;left:0; width:100%;
            .dis-none{
              display: none;
            }
            .reserve-cell {
            position: relative; height:1px; float:left;

            // .reserve-bg {
            //     width:100%; background-color:rgba(0,0,0,0.01); position: absolute;
            // }
            // .reserve-bg-in {
            //   width:100%; background-color:rgba(255,255,0,0.6); position: absolute;
            // }
            // .reserve-bg-owner {
            //   width:100%; background-color:blue; position: absolute;
            // }
            // .btn-reserve {
            //     border: 0;background-color: rgba(255,133,74,0.6);width:100%;height:100%;z-index: 1;

            //     &:hover {
            //         background-color: rgba(255,133,74,1);
            //     }
            //     &.owner {
            //         background-color:rgba(93,156,236,0.6);
            //         &:hover {
            //             background-color:rgba(93,156,236,1);
            //         }
            //     }
            // }

            .reserve-bg-in {
              width:100%; background-color:rgba(5,171,25,0.6); position: absolute;
              &:hover {
                .btn-reserve{
                  background-color:rgba(5,171,25,1);position: absolute;
                }
              }
            }
            .reserve-bg-out{
              width:100%; background-color:rgba(255,118,0,0.6); position: absolute;
              &:hover {
                .btn-reserve{
                  background-color:rgba(255,118,0,1);position: absolute;
                }
              }
            }
            .reserve-bg-owner{
              width:100%; background-color:rgba(0,114,255,0.6); position: absolute;
              &:hover {
                .btn-reserve{
                  background-color:rgba(0,114,255,1);position: absolute;
                }
              }
            }

              .none{
                width:100%; background-color:transparent; position: absolute;
                &:hover {
                  .btn-reserve{
                    background-color:transparent;position: absolute;
                  }
                }
              }
            .btn-reserve {
              border: 0;background-color:transparent;width:100%;height:100%;z-index: 1;
            }
          }
        }
      .over-text{
        position:absolute; z-index: 555; min-width: 100px;  background-color: #ffffff; border: 1px solid #ddd; box-shadow: 1px 1px 4px rgb(0,0,0,0.2); padding: 5px 11px; border-radius: 1px;font-size: 14px; font-weight: normal; line-height: 1.64; text-align: left; color: #555555; word-break: break-all; word-break: break-all; display: -webkit-box; word-wrap: break-word; -webkit-line-clamp: 9; -webkit-box-orient: vertical; text-overflow: ellipsis;

      }
    }
}
</style>